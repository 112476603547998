<template lang="pug">
  v-row.login.align-stretch
    v-col.login-left.d-flex.align-center(cols="12" md="8")
      v-img.login-left-image.mx-auto(:src="logoSrc" max-width="50%" )
    v-col.login-right.d-flex.align-center(cols="12" md="4")
      v-container
        form(@submit.prevent="submitForm")
        .px-15
          v-row.justify-center
            v-col(cols="12")
              .font-weight-medium.text-color--dark1 Magnus Recover Password
            v-col(cols="12" v-if="recoverPasswordError")
              v-alert(type="error" text) Unknown Error
            v-col(cols="12" v-else-if="!isSame")
              v-alert(type="error" text) Passwords must match
            v-col(cols="12" v-else-if="!isFilled")
              v-alert(type="error" text) Please fill in the required fields
            v-col(cols="12")
              .mb-4
                div.d-flex.justify-space-between
                  .text-color--dark1.body-2.font-weight-regular New Password
                v-text-field(
                  outlined
                  dense
                  hide-details
                  :type="(show ? 'text': 'password')"
                  v-model.trim="newPassword"
                )
                  template(#append v-if="show")
                    eye-icon.cp(size="1.3x" @click="show = !show")
                  template(#append v-else)
                    eye-off-icon.cp(size="1.3x" @click="show = !show")
            v-col(cols="12")
              .mb-4
                div.d-flex.justify-space-between
                  .text-color--dark1.body-2.font-weight-regular Verify New Password
                v-text-field(
                  outlined
                  dense
                  hide-details
                  :type="(showRepeat ? 'text': 'password')"
                  v-model.trim="newPasswordRepeat"
                )
                  template(#append v-if="showRepeat")
                    eye-icon.cp(size="1.3x" @click="showRepeat = !showRepeat")
                  template(#append v-else)
                    eye-off-icon.cp(size="1.3x" @click="showRepeat = !showRepeat")
            v-col(cols="12")
              v-btn.white--text(
                block
                color="#000138"
                type="submit"
                @click="submitForm"
                :disabled="recoverPasswordLoading"
              ) Save
</template>
<script>
import logoSrc from '@/assets/image/magnusLogin.svg'
import {EyeIcon, EyeOffIcon} from 'vue-feather-icons'
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: 'Login',

  components: {
    EyeIcon,
    EyeOffIcon
  },

  data() {
    return {
      show: false,
      showRepeat: false,
      logoSrc,
      newPassword: "",
      newPasswordRepeat: "",
      isSame: true,
      isFilled: true,
    }
  },

  computed: {
    ...mapState("Auth", [
      "recoverPasswordLoading",
      "recoverPasswordError",
    ])
  },

  methods: {
    ...mapActions("Auth", [
      "recoverPassword"
    ]),

    async submitForm() {
      const { newPassword, newPasswordRepeat } = this;

      if (this.recoverPasswordLoading) {
        return;
      }

      if (!newPassword || !newPasswordRepeat) {
        this.isFilled = false;
        return;
      }

      if (newPassword !== newPasswordRepeat) {
        this.isSame = false;
        return;
      } else {
        this.isSame = true;
      }

      const token = this.$route.query.token;

      if (token) {
        const success = await this.recoverPassword({
          token: token, 
          newPassword: newPassword,
        });

        if (success) {
          this.$router.push("/login");
        } else {
          return;
        }
      }
    },
  }
}
</script>

<style lang="sass" scoped>
.login
  height: 100vh

  &-left
    background-color: #000138
</style>
